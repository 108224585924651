import React, { useContext } from 'react';
import { Section } from '../components/section/section';
import { Title } from '../components/title/title';
import { Link } from '../components/link/link';
import { Subtitle } from '../components/subtitle/subtitle';
import { FullWidthImage } from '../components/image/full-width-image';
import { Container } from '../components/container/container';
import { Content } from '../components/content/content';
import { ServiceCards } from '../components/service-cards/service-cards';
import { Overlay } from '../components/overlay/overlay';
import { LayoutContext } from '../components/layout/layout';
import { Text } from '../components/text/text';
import { Meta } from '../components/meta/meta';

const About = () => {
  const { navOpen } = useContext(LayoutContext);

  return (
    <>
      <Meta
        title="About Us in Chesterfield"
        description="Chesterfield Family Chiropractic Center provides chiropractic care. Dr. Todd Kleinstein is a Chesterfield Chiropractor, call us today at 586-948-7246!!"
      />
      <Section layout="fullscreen" maxh={['none', 600]} css={{ backgroundColor: '#000' }}>
        <FullWidthImage name="clipboard-with-diagram-of-spine" type="bg" css={{ opacity: 0.4 }} />
        <Content h={1} css={{ display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center' }}>
          <Title mt="128px" color="#fff" hide={[navOpen, navOpen, 0]}>
            About Us
          </Title>
        </Content>
        <Content
          hide={[1, 0]}
          css={{
            position: 'absolute',
            bottom: 0,
            height: '80px',
            color: '#fff',
            backgroundColor: 'rgba(0, 133, 255, .2)',
            backdropFilter: 'blur(10px)',
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
          }}
        >
          <Link
            to="https://www.google.com/maps/place/Chesterfield+Family+Chiropractic+Center/@42.6834603,-82.8273306,17z/data=!4m13!1m7!3m6!1s0x88251ed3fc238b5d:0x10a6e47136ed588c!2s28039+Carriage+Way+Dr,+New+Baltimore,+MI+48051!3b1!8m2!3d42.6834564!4d-82.8251419!3m4!1s0x88251f8cde259aa5:0xbcb58aa34e618757!8m2!3d42.6834767!4d-82.8261288"
            variant="unstyled"
            css={{
              width: '50%',
              maxWidth: '800px',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderLeft: '2px solid #ffffff80',
              borderRight: '1px solid #ffffff80',
            }}
          >
            Chesterfield Location | Get Directions
          </Link>
          <Link
            to="https://www.google.com/maps/place/Dr.+Todd's+Wellness/@42.6311226,-83.3271957,17z/data=!4m13!1m7!3m6!1s0x8824be62ae3b1645:0xf9dead869a3d58fc!2s133+S+Telegraph+Rd,+Pontiac,+MI+48341!3b1!8m2!3d42.6311187!4d-83.325007!3m4!1s0x8824bf0242fcd2e5:0x6b5d0cd35c28ef3f!8m2!3d42.6311191!4d-83.3249962"
            variant="unstyled"
            css={{
              width: '50%',
              maxWidth: '800px',
              display: 'inline-flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderLeft: '1px solid #ffffff80',
              borderRight: '2px solid #ffffff80',
            }}
          >
            Pontiac Location | Get Directions
          </Link>
        </Content>
        <Overlay open={navOpen} />
      </Section>
      <Section>
        <Container maxw={960}>
          <Subtitle mb={64}>About Us</Subtitle>
          <Subtitle as="h3">A Beautiful Practice For All Your Wellness Needs</Subtitle>
          <Text>
            We are proud to provide a state-of-the-art facility for the highest quality chiropractic care available. It
            is one of our top priorities to protect the well-being of our valued patients. We are confident that you
            will feel right at home in our office as we welcome all patients as if they were family.
          </Text>
          <Subtitle as="h3">Appointments</Subtitle>
          <Text>
            If you would like to make an appointment, please contact us at (586) 948-7246. If you would like to request
            an appointment online, please select the &ldquot;Appointment Request&rdquo; option from the menu above. A
            member of our staff will follow up with you directly to confirm scheduling.
          </Text>
          <Subtitle as="h3">Financial</Subtitle>
          <Text>
            Our practice accepts cash, check, and major credit cards. We will be more than happy to submit your
            insurance forms for you, and we accept most major insurance providers. Please call us to verify whether your
            specific insurance plan is accepted. Payment plans and schedules can be discussed upon request.
          </Text>
          <Subtitle as="h3">Cancellation</Subtitle>
          <Text>
            If you are unable to keep an appointment, we ask that you kindly provide us with at least 24 hours notice.
            We ask for this advance notice so that we can offer this appointment to another patient. A fee may be
            charged if a patient does not show up for an appointment without sufficient notice.
          </Text>
        </Container>
      </Section>
      <Section>
        <Container maxw={960}>
          <Subtitle>Our Other Services</Subtitle>
        </Container>
        <ServiceCards />
      </Section>
    </>
  );
};

export default About;
