import stCss from '../../st-css';
import {variant, styleProps, textShorthandProps, spacingShorthandProps, sizeShorthandProps, visibilityShorthandProps } from 'st-css';

export const Title = stCss.canonize('h1')({
    color: '#2F80ED',
    fontSize: ['38px', '40px', '42px'],
    fontWeight: 'bold',
    lineHeight: ['48px','54px', '64px'],
    marginBottom: '24px'
}, variant('variant', {
    'filled': {
        fontSize: ['24px', '34px', '42px', '50px'],
        lineHeight: ['38px', '50px', '64px'],
        color: '#fff',
        borderRadius: '1em',
        backgroundColor: '#29DE92',
        py: '1.1em',
        px: ['1em', '2em', '3em'],
        mb: ['1em', '2em']
    }
}),
styleProps(textShorthandProps, spacingShorthandProps, sizeShorthandProps, visibilityShorthandProps));