import { graphql, useStaticQuery } from 'gatsby';
import { Image } from '../image/image';
import { Grid } from '../grid/grid';
import { Link } from '../link/link';
import { Subtitle } from '../subtitle/subtitle';
import { Icon } from '../icon/icon';

export const getServicesQuery = graphql`
  query {
    allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: { fields: { sourceInstanceName: { eq: "service" } } }
    ) {
      nodes {
        frontmatter {
          name
          slug
          heroImage {
            name
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: BLURRED
                width: 400
                height: 600
                transformOptions: { cropFocus: CENTER }
              )
            }
          }
        }
      }
    }
  }
`;

export const useServices = () => {
  const { allMdx } = useStaticQuery(getServicesQuery);
  return allMdx.nodes.map((n) => ({ ...n.frontmatter }));
};

export const ServiceCards = () => {
  const services = useServices();
  return (
    <Grid layout={['wall', 'wall', 'columns']} gap="0px" css={{ position: 'relative', backgroundColor: '#000103' }}>
      <Icon
        icon="arrows"
        show={[1, 1, 0]}
        css={{ zIndex: 1, h: 24, w: 24, position: 'absolute', right: '8px', top: '50%', transform: 'rotate(-90deg)' }}
      />
      {services.map(({ heroImage, slug, name }) => (
        <Link
          css={{ display: 'block', position: 'relative', ':hover': { backdropFilopacity: 0.6 } }}
          to={`/services/${slug}`}
          key={slug}
          variant="unstyled"
        >
          <Image
            image={heroImage}
            css={{
              transition: 'opacity .15s linear',
              opacity: 0.6,
              backdropFilter: 'blur(15px) opacity(.75)',
              ':hover': { opacity: '.3' },
            }}
            w={1}
          />
          <Subtitle
            as="h3"
            css={{
              width: '100%',
              fontSize: '36px !important',
              textAlign: 'center',
              fontWeight: 'normal',
              color: 'white',
              position: 'absolute',
              bottom: '64px',
            }}
          >
            {name}
          </Subtitle>
        </Link>
      ))}
    </Grid>
  );
};
