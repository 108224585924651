import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { Image } from './image';

export const getFullWidthImagesQuery = graphql`
  query {
    allFile(filter: { sourceInstanceName: { eq: "full-width-images" } }) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
          }
        }
      }
    }
  }
`;

export const FullWidthImage = (props) => {
  const { allFile } = useStaticQuery(getFullWidthImagesQuery);
  const { node } = allFile.edges.find(({ node }) => node.name === props.name) || {};
  if (!node) return '';
  return <Image image={node.childImageSharp.gatsbyImageData} {...props} />;
};
